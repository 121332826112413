import React from 'react';
import { IconButton } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { filterData } from "../../../utils/function";
import _ from 'lodash';

const columns = [
    { id: 'sel', label: 'Sel.', width: 50, align: 'center', },
    { id: 'id_centroCosto', label: 'Id', width: 80, align: 'center', },
    { id: 'code', label: 'Código', width: 90, align: 'left', },
    { id: 'names', label: 'CECO', width: 100, align: 'left', },
    { id: 'delete', label: 'Del.', width: 50, align: 'center', },
];

const BodyCeco = (props) => {
    const { dataCecos, dataCecoUser, dataInsert, setDataInsert, dataDelete, setDataDelete, search, } = props;

    let resultSearch = filterData(dataCecos, search, ['names']);

    const handleChangeCheck = (check, info) => {
        let updDataInsert = _.cloneDeep(dataInsert);
        if (check) {
            updDataInsert.push(info)
        } else {
            updDataInsert = updDataInsert.filter(e => e.id_centroCosto !== info.id_centroCosto)
        }

        return setDataInsert(updDataInsert)
    };

    const handleDeleteRow = (info) => {
        let updDataDelete = _.cloneDeep(dataDelete);
        updDataDelete.push(info)

        return setDataDelete(updDataDelete)
    };

    return (
        <div className="frame-table">
            <div className="table-responsive body-table">
                <table className="table table-hover table-striped table-bordered">
                    <thead className='sticky-top'>
                        <tr>
                            {columns.map((column) => (
                                <th
                                    scope="col"
                                    className='header-table'
                                    key={column.id}
                                    style={{
                                        textAlign: column.align,
                                        minWidth: `${column.width}px`,
                                        maxWidth: `${column.width + 5}px`
                                    }}
                                >
                                    {column.label}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="table-group-divider">
                        {resultSearch?.map((row, indexRow) => {
                            const validateColorRow = !!dataDelete?.find(e => e.id_centroCosto === row.id_centroCosto)
                            return (
                                <tr key={'row' + indexRow}>
                                    {columns.map((column, indexColumn) => {
                                        const value = row[column.id];
                                        return (
                                            <td
                                                key={'col' + indexColumn}
                                                align={column.align}
                                                style={{
                                                    backgroundColor: `${validateColorRow ? "#fadbd8" : ""}`
                                                }}
                                            >
                                                {column.id === '#' ? indexRow + 1
                                                    : column.id === 'sel' ?
                                                        <div className="checkbox-custom">
                                                            <ElementCheck
                                                                key={"codCeco-" + indexRow}
                                                                info={row}
                                                                id={"id_centroCosto"}
                                                                labelId={"id_centroCosto"}
                                                                label={"names"}
                                                                dataExists={dataCecoUser}
                                                                dataSelect={dataInsert}
                                                                handleChangeCheck={handleChangeCheck}
                                                            />
                                                        </div>
                                                        : (column.id === 'delete' &&
                                                            !!dataCecoUser?.find(e => e.id_centroCosto === row.id_centroCosto) &&
                                                            !validateColorRow
                                                        ) ?
                                                            <IconButton
                                                                onClick={() => handleDeleteRow(row)}
                                                                title="Eliminar archivo"
                                                            >
                                                                <RemoveCircleOutlineIcon color="error" style={{ fontSize: "medium" }} />
                                                            </IconButton>
                                                            : value
                                                }
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const ElementCheck = (props) => {
    const { info, id, labelId, label, dataExists, dataSelect, handleChangeCheck } = props;
    // console.log("***********");
    // console.log("***********");
    // console.log("🚀🥵 ~ ElementCheck ~ id:", id);
    // console.log("🚀🥵 ~ ElementCheck ~ info[id]:", info[id])
    // console.log("🚀🥵 ~ ElementCheck ~ dataSelect[0][id]:", dataSelect[0][id])
    // console.log("🚀🥵 ~ ElementCheck ~ dataSelect:", dataSelect?.find(e => +e[id] === +info[id]))
    // console.log("🚀🥵 ~ ElementCheck ~ info:", info)
    // console.log("🚀🥵 ~ ElementCheck ~ dataSelect:", dataSelect)
    return (
        <>
            <input type="checkbox" id={`checkbox-rect-${info[labelId]}`} name="check"
                checked={(dataExists?.find(e => e[id] === info[id]) ||
                    dataSelect?.find(e => e[id] === info[id]))
                    ? true : false}
                onChange={(e) => handleChangeCheck(e.target.checked, info)}
                disabled={!!dataExists?.find(e => e[id] === info[id])}
            />
            <label
                htmlFor={`checkbox-rect-${info[labelId]}`}
                className={`${dataExists?.find(e => e[id] === info[id]) ? 'enabled' : ''}`}
            ></label>
        </>
    )
}

export default BodyCeco