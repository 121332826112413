import React, { useState } from 'react';
import { ButtonGroup, IconButton } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import CustomPagination from "../general/CustomPagination";
import { sortDataList } from "../../../utils/function";

const columns = [
    { id: 'desEmpresa', label: 'Empresa', width: 600, align: 'left', },
    { id: 'desCliente', label: 'Cliente', width: 600, align: 'left', },
    { id: 'desUnidad', label: 'Unidad', width: 600, align: 'left', },
    { id: 'desSede', label: 'Sede', width: 600, align: 'left', },
    { id: 'names', label: 'CECO', width: 600, align: 'left', },
    { id: 'estado', label: 'Estado', width: 400, align: 'left', },
    { id: 'actions', label: 'Acción', width: 200, align: 'center', },
];

const BodyVerifyLegajo = (props) => {
    const { handleOpenLegajo, dataLegajo, totalPages, page, setPage, } = props;

    const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });

    const handleSortTable = (key) => {
        let direction = "ascending";
        if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
            direction = "descending";
        }
        setSortConfigTable({ key, direction });
    };

    const sortedDataWorkers = sortDataList(dataLegajo, sortConfigTable);

    return (
        <div className="frame-table">
            <div className="table-responsive body-table">
                <table className="table table-hover table-striped table-bordered">
                    <thead className='sticky-top' style={{ zIndex: 1 }}>
                        <tr>
                            {columns.map((column) => (
                                <th
                                    scope="col"
                                    className='header-table'
                                    key={column.id}
                                    style={{
                                        textAlign: column.align,
                                        width: column.width,
                                        maxWidth: column.width
                                    }} onClick={() => handleSortTable(column.id)}
                                >
                                    {column.label}

                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="table-group-divider">
                        {sortedDataWorkers?.map((group, indexRow) => {
                            return (
                                <tr key={'row' + indexRow} style={{ whiteSpace: 'nowrap' }}>
                                    {columns.map((column, indexColumn) => {
                                        const value = group[column.id];
                                        return (
                                            <td key={'col' + indexColumn} align={column.align}>
                                                {column.id === 'actions' ?
                                                    <ButtonGroup disableElevation variant="contained" aria-label="Disabled elevation buttons">
                                                        <IconButton
                                                            aria-label="delete"
                                                            size="small"
                                                            title="Ver Archivos"
                                                            onClick={() => handleOpenLegajo(group, 'VIEW')}
                                                        >
                                                            <FolderIcon fontSize="small" color={'warning'} />
                                                        </IconButton>
                                                    </ButtonGroup>
                                                    : column.id === 'estado' ?
                                                        (+value === 1 ? 'ACTIVO' : 'CESADO')
                                                        : value}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <CustomPagination
                totalPages={totalPages}
                page={page}
                setPage={setPage}
            />
        </div>
    );
};

export default BodyVerifyLegajo