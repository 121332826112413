import axios from "axios";
import { Global } from '../utils/general';
import headerRequest from "../utils/headers"

const API_URL = `${Global.url}/menu`;

class MenuService {
    async getMenu() {
        const ruta = `${API_URL}`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }
    async getMenuByUser() {
        const ruta = `${API_URL}/by-user`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            headers: headers,
        }).catch((error) => {
            return error;
        });
    }
}

export default new MenuService();