import React, { useState } from 'react'
import CustomInput from '../../general/CustomInput';
import BodyCeco from '../BodyCeco';

const AssociateCeco = (props) => {
    const { dataCecos, dataCecoUser, dataInsert, setDataInsert, dataDelete, setDataDelete, } = props

    const [search, setSearch] = useState("")
    const dataCecosList = dataCecos?.filter(e => +e.id_centroCosto !== -1);

    return (
        <div>
            <div style={{ margin: '10px' }}>
                <CustomInput
                    placeholder={"Buscar por descripción"}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <BodyCeco
                dataCecos={dataCecosList}
                dataCecoUser={dataCecoUser}
                dataInsert={dataInsert}
                setDataInsert={setDataInsert}
                dataDelete={dataDelete}
                setDataDelete={setDataDelete}
                search={search}
                setSearch={setSearch}
            />
        </div>
    )
}

export default AssociateCeco