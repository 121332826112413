import React, { useState, useEffect } from 'react'
import CustomModal from '../../../components/general/CustomModal';
import CustomButton from '../../../components/general/CustomButton';
import AssociateCeco from '../../../components/Maintenance/AddCeco/AssociateCeco';
import UserService from '../../../../services/user';
import { CODES } from '../../../../utils/codesHTTP';

const AddCeco = (props) => {
    const { open, setOpen, setOpenLoader, setTitleLoader, saveProfileUser,
        dataCecos, selectedUser, dataInsert, setDataInsert, dataDelete, setDataDelete, } = props;

    const [informationUser, setInformationUser] = useState(null);
    useEffect(() => {
        setInformationUser(selectedUser);
        if (selectedUser && open) {
            getCecosByUser()
        }
    }, [selectedUser, open])

    const cleanInfo = () => {
        setOpen(false);
        setInformationUser(null);
        setDataInsert([]);
        setDataDelete([]);
        // setRowsFiles(null);
        // setStateModal(null);
        // setDetailLegajo(null);
        // setDetailLegajoAUD(null);
        // setValueType(0);
    }

    const [dataCecoUser, setDataCecoUser] = useState(null);
    const getCecosByUser = async () => {
        try {
            setOpenLoader(true);
            setTitleLoader('Buscando ...');
            setDataCecoUser([]);
            const result = await UserService.getCecosByUser(informationUser?.id_usuario)
            if (result.status === CODES.SUCCESS_200) {
                setDataCecoUser(result.data);
            }
            return []
        } catch (error) {
            console.log(`Error en getCecosByUser: ${error}`);
        } finally {
            setOpenLoader(false);
            setTitleLoader(null);
        }
    }

    return (
        <CustomModal
            size={'md'}
            open={open}
            handleClose={cleanInfo}
            title={'Asociar Centro de Costos'}
            subTitle={'Asociar cecos a usuarios'}
            bodyModal={
                <AssociateCeco
                    setOpenLoader={setOpenLoader}
                    setTitleLoader={setTitleLoader}
                    dataCecos={dataCecos}
                    informationUser={informationUser}
                    dataCecoUser={dataCecoUser}
                    dataInsert={dataInsert}
                    setDataInsert={setDataInsert}
                    dataDelete={dataDelete}
                    setDataDelete={setDataDelete}
                />
            }
            buttonActions={
                [
                    <CustomButton
                        key={'btn-save'}
                        variant={'primary'}
                        value={'Guardar'}
                        styleType={'outline'}
                        onClick={saveProfileUser}
                    />
                ]
            }
        />
    )
}

export default AddCeco