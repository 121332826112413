import React, { useState, useEffect } from 'react';
import "../../../assets/styles/navbar.scss";
import { useNavigate } from 'react-router-dom';
import MenuService from "../../../services/menu";
import AuthService from "../../../services/auth";
import { CODES } from '../../../utils/codesHTTP';
import { Global } from "../../../utils/general";

const NavBar = ({ setUserToken }) => {
    const navigate = useNavigate();
    const pathInit = Global.path || '';
    const info = Global.info;
    const infoTime = Global.infoTime;
    const [menu, setMenu] = useState(null);

    useEffect(() => {
        getMenu();
    }, []);

    const getMenu = async () => {
        try {
            const result = await MenuService.getMenuByUser();
            if (result?.status === CODES.SUCCESS_200) {
                setMenu(result?.data)
            } else if (result?.code === 'ERR_NETWORK') {
                localStorage.removeItem(info);
                setUserToken(null)
                window.location.reload();
            }
            return [];
        } catch (error) {
            console.log(`Error en MenuService: ${error}`);
        }
    }

    const [activeItem, setActiveItem] = useState(null);
    const handleItemClick = (route) => {
        navigate(route);
        setActiveItem(route);
    };

    const signOut = async () => {
        try {
            const result = await AuthService.signOut();
            localStorage.removeItem(info);
            localStorage.removeItem(infoTime);
            setUserToken(null)
            window.location.href = pathInit;
            return [];
        } catch (error) {
            console.log(`Error en MenuService: ${error}`);
        }
    }

    return (
        <nav className={`navbar navbar-expand-lg fixed-top site-navbar`}>
            <div className="container">
                <a className={`navbar-brand`} href="#">AppLegajo</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className={`navbar-nav me-auto fs-small`}>
                        {menu?.map((item) => {
                            return (
                                <li
                                    className={`nav-item ${activeItem === `${item.link}` ? 'active' : ''}`}
                                    key={item.label}
                                    onClick={() => handleItemClick(`${item.link}`)}
                                >
                                    <a className='nav-link'>
                                        <span>{item.label}</span>
                                    </a>
                                </li>
                            )
                        })}
                        <li className="nav-item dropdown">
                            <a
                                className={`nav-link dropdown-toggle`} href=""
                                id={`navbarDropdown-varios`} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Varios
                            </a>
                            <ul
                                className={`dropdown-menu`}
                                aria-labelledby={`navbarDropdown-varios`}
                            >
                                <li
                                    className={`nav-item-menu`}
                                    onClick={signOut}
                                >
                                    <a className='nav-link-menu'>
                                        <span>Cerrar Sesión</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default NavBar